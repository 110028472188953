import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { delay } from 'rxjs';
import { PagingData } from '../models';
import { Store } from '../models/catalog.model';
import {
  CrmFrontMessage,
  FrontTopMessageSummary,
  MessagesGroupResult,
  TopMsgStoreMap,
} from '../models/message.model';
import { AmmMessageService, ProductService } from '../services';
import { generateMsgDayGroupKey, groupMessageByDate } from '../utilitys';
import { TopMsgMessageGroupMap } from './../models/message.model';

export interface InitState {
  conversations: FrontTopMessageSummary[];
  stores: Store[];
  topMsgMessageGroupMap: TopMsgMessageGroupMap[];
  currentConversationMsgs: CrmFrontMessage[];
  totalPages: number;
  loadingConversationResult: {
    isLoading: boolean;
    loadingError: boolean;
  };
}

export const initState: InitState = {
  conversations: [],
  stores: [],
  topMsgMessageGroupMap: [],
  currentConversationMsgs: [],
  totalPages: 0,
  loadingConversationResult: { isLoading: true, loadingError: false },
};

export const MessagingStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initState),
  withComputed((store) => ({
    topMsgStoreMaps: computed(() => {
      const conversations = store.conversations();
      const stores = store.stores();
      const result: TopMsgStoreMap[] = [];
      conversations.forEach((con) => {
        const store = stores.find((x) => x.code === con.groupRef);
        if (store && con && con.topMsgId) {
          result.push({
            topMsgId: con.topMsgId,
            store: store,
          });
        }
      });

      return result;
    }),
    sortedMesages: computed(() => {
      return store
        .currentConversationMsgs()
        .sort(
          (a, b) =>
            new Date(a.createdDate!).getTime() -
            new Date(b.createdDate!).getTime()
        );
    }),
    hasUnreadMsgs: computed(() => {
      const conversations = store.conversations();
      const unreadCounts = conversations.map((x) => x.unreadCount);
      const sum = unreadCounts
        .filter((x) => x)
        .reduce((acc, curr) => {
          acc = (acc ?? 0) + (curr ? curr : 0);
          return acc;
        }, 0);
      return sum ?? 0 > 0;
    }),
  })),
  withMethods(
    (
      store,
      ammMsgSrv = inject(AmmMessageService),
      prodSrv = inject(ProductService)
    ) => ({
      loadConversations() {
        ammMsgSrv
          .fetchTopMessage()
          .subscribe((response: PagingData<FrontTopMessageSummary>) => {
            if (response && response.dataList && response.dataList.length > 0) {
              patchState(store, {
                conversations: response.dataList,
              });
              this.loadStoreInfo();
            }
          });
      },
      loadStoreInfo() {
        const storeCodes =
          store
            .conversations()
            .filter((x) => x.groupRef !== void 0)
            .map((x) => x.groupRef!) || [];
        prodSrv.getStoreByCodes(storeCodes).subscribe((amStores: Store[]) => {
          patchState(store, {
            stores: amStores,
          });
        });
      },
      loadConversationById(
        topMsgId: number,
        pageIndex: number = 0,
        pageSize: number = 12
      ) {
        ammMsgSrv
          .fetchConversationByTopMsgId(topMsgId, pageIndex, pageSize)
          .pipe(delay(1000))
          .subscribe((response: PagingData<CrmFrontMessage>) => {
            if (response && response.dataList && response.dataList.length > 0) {
              // group the messages by date
              const messages = response.dataList;
              patchState(store, {
                currentConversationMsgs: store
                  .currentConversationMsgs()
                  .concat(messages),
                totalPages: response.totalPages,
                loadingConversationResult: {
                  ...store.loadingConversationResult(),
                  isLoading: false,
                },
              });

              const groupByDate = groupMessageByDate(store.sortedMesages());
              const messageAlreadyExists = store
                .topMsgMessageGroupMap()
                .find((x) => x.topMsgId === topMsgId);
              if (messageAlreadyExists) {
                TopMsgMessageGroupMap: store
                  .topMsgMessageGroupMap()
                  .map((x) =>
                    x.topMsgId == topMsgId ? (x.groupResults = groupByDate) : x
                  );
              } else {
                patchState(store, {
                  topMsgMessageGroupMap: [
                    ...store.topMsgMessageGroupMap(),
                    {
                      topMsgId: topMsgId,
                      groupResults: groupByDate,
                    },
                  ],
                });
              }
            }
          });
      },
      updateLoadingStatus(status: boolean) {
        patchState(store, {
          loadingConversationResult: {
            ...store.loadingConversationResult(),
            isLoading: status,
          },
        });
      },
      clearCurrentConversationMsgs() {
        patchState(store, {
          currentConversationMsgs: [],
        });
      },
      clearTotalPages() {
        patchState(store, {
          totalPages: 0,
        });
      },
      clearLoadingStatus() {
        patchState(store, {
          loadingConversationResult: { isLoading: true, loadingError: false },
        });
      },
      addNewMsgToGroup(topMsgId: number, message: CrmFrontMessage) {
        const key = generateMsgDayGroupKey(message);
        const groupMaps = store.topMsgMessageGroupMap();
        const conversationMap = groupMaps.find((x) => x.topMsgId == topMsgId);
        if (conversationMap) {
          const group = conversationMap.groupResults.find((x) => x.key == key);
          if (group) {
            group.messages.push(message);
          } else {
            groupMaps.push(<TopMsgMessageGroupMap>{
              topMsgId: topMsgId,
              groupResults: [
                <MessagesGroupResult>{
                  key: key,
                  messages: [message],
                },
              ],
            });
          }
          patchState(store, {
            topMsgMessageGroupMap: groupMaps,
          });
        }
      },
      saveNewMsgToDb(topMsgId: number, message: CrmFrontMessage) {
        ammMsgSrv.replyMessageByTopId(message, topMsgId).subscribe();
      },
      handleMsgFromSse(msgId: number) {
        ammMsgSrv
          .fetchAllMessageById(msgId)
          .subscribe((response: PagingData<CrmFrontMessage>) => {
            if (response && response.dataList && response.dataList.length > 0) {
              const lastestMsgInConversation = response.dataList[0];
              const topMsgId = lastestMsgInConversation.topMsgId ?? 0;

              this.addNewMsgToGroup(topMsgId, lastestMsgInConversation);
              this.loadConversations();
            }
          });
      },
      readMsgs(ids: number[]) {
        ammMsgSrv
          .setWholeThreadToReadStatus(ids)
          .subscribe((response: number) => {
            this.loadConversations();
          });
      },
    })
  )
);
